@import '../../styles/index';

.base {
  align-items: center;
  border-radius: 2.625rem;
  color: $color-white--100;
  display: flex;
  gap: 0.25rem;
  height: 2.5rem;
  justify-content: center;
  left: 50%;
  padding-inline: 0.8rem;
  position: absolute;
  top: 50%;

  &.existing {
    background-color: $color-turquoise--100;
  }

  &.planned {
    background-color: $color-violet--100;
  }
}

.text {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.33;
}
