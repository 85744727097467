@import '../../styles/index';

.base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.125rem;
  max-width: 13.75rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1.875rem;
  background-color: $color-white--100;
  color: $color-surrogate--100;
  box-shadow: $shadow-eng-map-menu;
  cursor: pointer;
  overflow: hidden;
  z-index: $index-map-toggle;

  .toggle-icon {
    margin-right: 0.5rem;
  }

  .toggle-text {
    display: inline-block;
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
  }

  .animated-text {
    align-self: flex-start;
  }

  .animated-label {
    position: relative;
    width: 100%;
    transition: transform $duration-long;

    .animated-label-item {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    .animated-label {
      transform: translateY(-50%);
    }
  }
}
